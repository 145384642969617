type PropsTypeObj = {
    [x: string]: object | [] | FormDataEntryValue | string | number | null;
};

const isSameDataInObjects = (
    obj1: PropsTypeObj,
    obj2: PropsTypeObj,
): boolean => {
    // Helper function to compare values deeply
    function compareValues(val1, val2) {
        if (typeof val1 !== typeof val2) return false;

        if (typeof val1 === 'object' && val1 !== null && val2 !== null) {
            if (Array.isArray(val1) && Array.isArray(val2)) {
                if (val1.length !== val2.length) return false;
                return val1.every((item, index) =>
                    compareValues(item, val2[index]),
                );
            } else if (!Array.isArray(val1) && !Array.isArray(val2)) {
                return deepCompare(val1, val2);
            } else {
                return false;
            }
        }

        if (typeof val1 === 'string') {
            return val1.trim() === val2.trim();
        }

        return val1 === val2;
    }

    // Main deep comparison function
    function deepCompare(obj1, obj2, skipIdCheck = true) {
        if (
            typeof obj1 !== 'object' ||
            obj1 === null ||
            typeof obj2 !== 'object' ||
            obj2 === null
        ) {
            return false;
        }

        // Compare keys that are in the first object
        for (const key of Object.keys(obj1)) {
            if (skipIdCheck && key === 'id') {
                continue; // Skip 'id' key because newly created row counters doesn't have id
            }

            console.log('key', key);

            if (!(key in obj2)) return false;

            if (!compareValues(obj1[key], obj2[key])) return false;
        }

        return true;
    }

    return deepCompare(obj1, obj2, false); // Project id need check here
};

export default isSameDataInObjects;
